import React, {useEffect} from 'react';
import styled from 'styled-components';

import ReCAPTCHA from "react-google-recaptcha";


const CONTAINER = styled.div`
  background: #F7F9FA;
  height: auto;
  width: 90%;
  margin: 5em auto;
  //margin-top: 5em auto;
  // margin-left: auto;
  //margin-right: auto;
  //margin-bottom: 5em auto;
  color: snow;
  opacity: 0.95;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);

  @media(min-width: 786px) {
    width: 60%;
  }

  label {
    color: #24B9B6;
    font-size: 1.2em;
    font-weight: 400;
  }

  .error {
    border: 2px solid #FF6565;
  }

  .error-message {
    color: #FF6565;
    padding: .5em .2em;
    height: 1em;
    position: absolute;
    font-size: .8em;
  }

  h1 {
    color: #24B9B6;
    padding-top: .5em;
  }

  .form-group {
    margin-bottom: 2.5em;
  }
`;


function RecaptchaChallenge(props){

    const recaptchaRef = React.createRef();
    function onChange(value) {
        value !== null  ? props.setBot(false) : props.setBot(true)
        value !== null  ? props.setToken(value) : props.setToken("")

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({token: value}, null, 2)
        };
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/verifytoken`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                const resultObj = JSON.parse(result)
                //console.log(resultObj.success)
               props.setVerified(resultObj.success)
                //props.setBackgroundImage(resultObj.success)
                props.setBackgroundImage(false)

            });
    }
    //useEffect(() =>{recaptchaRef.current.execute();},[])
    return(
    <CONTAINER>
        <div style={{display: 'flex', justifyContent: 'center'}}>
    <ReCAPTCHA
        ref={recaptchaRef}
        hl={'nl'}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        onChange={onChange}
    />
        </div>
    </CONTAINER>
    )
}

export default RecaptchaChallenge;