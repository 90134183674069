import React from 'react';
import styled from 'styled-components';
import { Form, Button } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';


const CONTAINER = styled.div`
  background: #F7F9FA;
  height: auto;
  width: 90%;
  //margin: 5em auto;
  //margin-top: 5em auto;
  margin-left: auto;
  margin-right: auto;
  //margin-bottom: 5em auto;
  color: snow;
  opacity: 0.95;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);

  @media(min-width: 786px) {
    width: 60%;
  }

  label {
    color: #24B9B6;
    font-size: 1.2em;
    font-weight: 400;
  }

  .error {
    border: 2px solid #FF6565;
  }

  .error-message {
    color: #FF6565;
    padding: .5em .2em;
    height: 1em;
    position: absolute;
    font-size: .8em;
  }

  h1 {
    color: #24B9B6;
    padding-top: .5em;
  }

  p {
    color: #24B9B6;
    padding-top: .5em;
  }

  .form-group {
    margin-bottom: 2.5em;
  }
`;

const MYFORM = styled(Form)`
  width: 90%;
  text-align: left;
  padding-top: 2em;
  padding-bottom: 2em;

  @media(min-width: 786px) {
    width: 50%;
  }
`;

const BUTTON = styled(Button)`
  background: #1863AB;
  border: none;
  font-size: 1.2em;
  font-weight: 400;

  &:hover {
    background: #1D3461;
  }
`;

// RegEx for phone number validation
const phoneRegExp = /^(((\\+31|0|0031)6){1}[1-9]{1}[0-9]{7})$/i;

// Schema for yup
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "*We hebben iets meer invoer van je nodig")
        .max(100, "*Je naam mag niet langer dan 100 tekens zijn")
        .required("*Je naam is vereist"),
    email: Yup.string()
        .email("*Dit moet een geldig e-mailadres zijn")
        .max(100, "*E-mailadres mag niet langer zijn dan 100 tekens")
        .required("*je e-mailadres is vereist"),
    private_email: Yup.string()
        .email("*Dit moet een geldig e-mailadres zijn")
        .max(100, "*E-mailadres mag niet langer zijn dan 100 tekens")
        .required("*je e-mailadres is vereist"),
    phone: Yup.string()
        .matches(phoneRegExp, "*Het telefoonnummer is niet valide"),

});

const ResetForm = (props) => {
    return(
        <CONTAINER>
            <center><h1>Self service password reset</h1></center>
            <center><p>Op deze pagina reset je je Humankind wachtwoord,<br/> volg de stappen zoals aangegeven</p></center>
            <Formik
                initialValues={{ name:"", email:"", private_email:"", phone:""}}
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    // When button submits form and form is in the process of submitting, submit button is disabled
                    setSubmitting(true);
                    values.recaptcha = props.token

                    // Simulate submitting to database, shows us values submitted, resets form
                    setTimeout(() => {

                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(values, null, 2)
                        };
                        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/ssrp`, requestOptions)
                            .then(response => response.json())
                            .then((result) => {

                                // const resultObj = JSON.parse(result)
                                // console.log(resultObj)
                                if(result.message === "Accepted"){
                                props.setNotificationMessage("je aanvraag is in behandeling, controleer je opgegeven prive emailadres voor verdere instructies")
                                props.setShowNotification(true)
                                    props.setRequestDone(true)
                                }

                                else{
                                    props.setNotificationMessage("Er is iets mis gegaan. Neem contact op met de servicedesk")
                                    props.setShowNotification(true)
                                    props.setRequestDone(true)}

                                });

                        //alert(JSON.stringify(values, null, 2));
                        resetForm();
                        setSubmitting(false);
                    }, 500);
                }}
            >
                {( {values,
                       errors,
                       touched,
                       handleChange,
                       handleBlur,
                       handleSubmit,
                       isSubmitting }) => (
                    <MYFORM onSubmit={handleSubmit} className="mx-auto">
                        <Form.Group controlId="formName">
                            <Form.Label>Wat is je naam</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="Voornaam en achternaam"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                className={touched.name && errors.name ? "has-error" : null}
                            />
                            {touched.name && errors.name ? (
                                <div className="error-message">{errors.name}</div>
                            ): null}
                        </Form.Group>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Voer je werk e-mailadres in</Form.Label>
                            <Form.Control
                                type="text"
                                name="email"
                                placeholder="Humankind e-mailadres"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                className={touched.email && errors.email ? "has-error" : null}
                            />
                            {touched.email && errors.email ? (
                                <div className="error-message">{errors.email}</div>
                            ): null}
                        </Form.Group>
                        <Form.Group controlId="formPrivateEmail">
                            <Form.Label>Voer je prive e-mailadres in</Form.Label>
                            <Form.Control
                                type="text"
                                name="private_email"
                                placeholder="Prive e-mailadres zoals bekend bij ons"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.private_email}
                                className={touched.private_email && errors.private_email ? "has-error" : null}
                            />
                            {touched.private_email && errors.private_email ? (
                                <div className="error-message">{errors.private_email}</div>
                            ): null}
                        </Form.Group>
                        <Form.Group controlId="formPhone">
                            <Form.Label>Voer je mobiele telefoonnummer in</Form.Label>
                            <Form.Control
                                type="text"
                                name="phone"
                                placeholder="Mobiele nummer zoals bekend bij ons"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                className={touched.phone && errors.phone ? "has-error" : null}
                            />
                            {touched.phone && errors.phone ? (
                                <div className="error-message">{errors.phone}</div>
                            ): null}
                        </Form.Group>
                        {/*Submit button that is disabled after button is clicked/form is in the process of submitting*/}
                        <BUTTON variant="primary" type="submit" disabled={isSubmitting}>
                            Verzend
                        </BUTTON>
                    </MYFORM>
                )}
            </Formik>
        </CONTAINER>
    );
}

export default ResetForm;