import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import ResetForm from './components/Form/ResetForm'
import RecaptchaChallenge from './components/recaptchav2/RecaptchaChallenge'
import Notification from './components/Notification/Notification'
//import styled from 'styled-components';
import background from "./img/bg.jpg";
import {useState} from "react";


function App() {
    const [bot, setBot] = useState(true)
    const [token, setToken] = useState(null)
    const [verified, setVerified] = useState(false)
    const [backgroundImage, setBackgroundImage] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState("")
    const [showNotification, setShowNotification] = useState(false)
    const [requestDone, setRequestDone] = useState(false)

  return (
    <div className="App" style={ backgroundImage ? { height: `100vh`, backgroundImage: `url(${background})` } : {}} >
        {showNotification && (
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            <Notification setShowNotification={setShowNotification} notificationMessage={notificationMessage}/>
            </div>
                )}
        {bot && (
            <RecaptchaChallenge
                setBot={setBot}
                setToken={setToken}
                setVerified={setVerified}
                setBackgroundImage={setBackgroundImage}
            />)}
        {!bot && verified && !requestDone && (
        <ResetForm token={token} setNotificationMessage={setNotificationMessage} setShowNotification={setShowNotification} setRequestDone={setRequestDone} />
        )}
    </div>
  );
}

export default App;
