import React, {useEffect} from 'react';
import {Toast} from "react-bootstrap";
import ResetForm from "../Form/ResetForm";

function Notification(props) {
    return(
    <Toast onClose={() => {props.setShowNotification(false)}}>
        <Toast.Header>
            <strong className="mr-auto">Reset password</strong>
            <small>{new Date().toLocaleTimeString()}</small>
        </Toast.Header>
        <Toast.Body>{props.notificationMessage}</Toast.Body>
    </Toast>
    )
}

export default Notification;